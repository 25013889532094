import Repository from './Repository';
import paginateRepository from './AllPaginateRepository';
import helpers from '../services/helpers';
const perujuks = "/perujuks";
const requestLink = '/perujuks/request-link-user'
const searchPerujuk = '/search-perujuks'
const defaultConfigs = { params: { "size": 1000 } };

export default {
    get(req , resolve , reject) {
        new Promise((resolve, reject) => {
            paginateRepository.getAllPaginate(perujuks, req, [], 0 , resolve ,reject);
        }).then((response) => {
            resolve({data: response});
        }).catch(error => {
            reject(error);
        });
    },
    getPerujukWithQuery(query, isSearch) {
        const querySearch = isSearch ? `?q=${query}` : ``
        return Repository.get(`${searchPerujuk}${querySearch}`)
    },
    getPerujuksWithQeury(queryParams) {
        return Repository.get(`${perujuks}?${queryParams}`)
    },
    getperujuks(perujuksId) {
        return Repository.get(`${perujuks}/${perujuksId}`);
    },
    createPerujuks(payload) {
        return Repository.post(`${perujuks}`, payload);
    },
    updatePerujuks(payload) {
        return Repository.put(`${perujuks}`, payload);
    },
    deletePerujuks(perujuksId) {
        return Repository.delete(`${perujuks}/${perujuksId}`);
    },
    requestLink(perujukId) {
        return Repository.post(`${requestLink}/${perujukId}`)
    }
}
